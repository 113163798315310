import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, X } from "lucide-react";
import LogoSvg from "../../assets/Your_paragraph_text__2_-removebg-preview (1).png";
import "./style.css";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  // Close mobile menu when route changes
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".navbar-container")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isOpen]);

  const handleNavigation = (path) => {
    // Prevent navigation if already on the same page
    if (location.pathname === path) {
      setIsOpen(false);
      return;
    }

    // Smooth scroll to top before navigation
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Delay navigation slightly to allow for smooth scroll
    setTimeout(() => {
      navigate(path);
      setIsOpen(false);
    }, 100);
  };

  const navigationItems = [
    { path: "/", label: "Home" },
    { path: "/services", label: "Services" },
    { path: "/about", label: "About us" },
    { path: "/career", label: "Career" },
    { path: "/contact", label: "Contact us" },
  ];

  return (
    <div className="navbar-wrapper">
      <div className="navbar-container">
        <div className="navbar-div">
          <div className="nav-image" onClick={() => handleNavigation("/")}>
            <img src={LogoSvg} alt="nav-logo" className="nav-logo" />
          </div>
          <button
            className="hamburger-icon"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            aria-label={isOpen ? "Close menu" : "Open menu"}
          >
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        <div className={`nav-item ${isOpen ? "open" : ""}`}>
          {navigationItems.map((item) => (
            <div
              key={item.path}
              className={`items ${
                location.pathname === item.path ? "active" : ""
              }`}
              onClick={() => handleNavigation(item.path)}
            >
              {item.label}
            </div>
          ))}
        </div>

        <div
          className="nav-item-quote"
          onClick={() => handleNavigation("/contact")}
        >
          Get a free Quote
        </div>
      </div>
    </div>
  );
};

export default Navbar;
