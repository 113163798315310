import React from "react";
import { useNavigate } from "react-router-dom";
import CarouselButton from "../../components/Button/CarouselButton";
import "./style.css";
const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="hero-container">
      <div className="hero-overlay"></div>

      <div className="home-carousel">
        <CarouselButton />
      </div>

      <div className="hero-content">
        <h1 className="hero-title">Behelam</h1>
        <p className="hero-subtitle">Crafting Customer-Centric Solutions</p>

        <div className="hero-btns">
          <button className="hero-button" onClick={() => navigate("/services")}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
