import React from "react";
import CarouselButton from "../../components/Button/CarouselButton";
import "./style.css";

const Services = () => {
  const services = [
    {
      image: require("./images/web.png"),
      title: "Web Development",
      description:
        "Transforming ideas into digital experiences through innovative web development solutions.",
    },
    {
      image: require("./images/mobile.png"),
      title: "Mobile Development",
      description:
        "Expert mobile development services transforming ideas into seamless mobile experiences. iOS, Android, cross-platform solutions. Innovative, user-centric apps.",
    },
    {
      image: require("./images/ui.png"),
      title: "Enterprise Software",
      description:
        "Transform your business with powerful enterprise software solutions. Streamline operations, boost productivity, and drive growth. Experience efficiency like never before.",
    },
    {
      image: require("./images/web.png"),
      title: "Contract Staffing",
      description:
        "Flexible workforce solutions for your business. Harness the power of contract staffing to meet your dynamic staffing needs with ease.",
    },
  ];

  const steps = [
    {
      number: "01",
      title: "Expertise",
      description:
        "Our team comprises highly skilled professionals with a diverse range of expertise, ensuring that we have the knowledge and skills to tackle any software development challenge.",
    },
    {
      number: "02",
      title: "Customization",
      description:
        "We believe in tailoring our solutions to your unique requirements, providing personalized experiences that align with your business goals.",
    },
    {
      number: "03",
      title: "Quality",
      description:
        "We have a strong commitment to delivering high-quality solutions that are thoroughly tested and built to stand the test of time.",
    },
    {
      number: "04",
      title: "Reliability",
      description:
        "Our focus on timely delivery and open communication ensures that you can rely on us as a trusted partner throughout your project's lifecycle.",
    },
    {
      number: "05",
      title: "Innovation",
      description:
        "We stay up-to-date with the latest industry trends and technologies, bringing innovative ideas to the table and helping you stay ahead in the competitive landscape.",
    },
  ];

  return (
    <div className="services-wrapper">
      <div className="carousel-nav">
        <CarouselButton />
      </div>

      <section className="hero-section">
        <div className="hero-overlay"></div>
        <h1 className="hero-title">Our Services</h1>
      </section>

      <section className="services-grid-section">
        <div className="services-grid">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <div className="service-content">
                <img
                  src={service.image}
                  alt={service.title}
                  className="service-image"
                />
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="why-us-section">
        <div className="why-us-content">
          <h2 className="section-title">Why us?</h2>
          <p className="section-description">
            Anticipating your business needs for future expansion, we ponder the
            requirements with all our senses, research with all our caliber, and
            learn with all our intellect to devise the perfect solution.
          </p>

          <div className="steps-container">
            {steps.map((step, index) => (
              <div key={index} className="step-item">
                <div className="step-number">{step.number}</div>
                <div className="step-content">
                  <h3 className="step-title">{step.title}</h3>
                  <p className="step-description">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
