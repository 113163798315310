import React from "react";
import { Facebook, Twitter, Instagram, Youtube, Linkedin } from "lucide-react";
import "./style.css";
const Footer = () => {
  const socialLinks = [
    { icon: Facebook, label: "Facebook", url: "/" },
    { icon: Instagram, label: "Instagram", url: "/" },
    { icon: Youtube, label: "Youtube", url: "/" },
    { icon: Twitter, label: "Twitter", url: "/" },
    {
      icon: Linkedin,
      label: "Linkedin",
      url: "https://www.linkedin.com/company/nexonetcis-solution-and-services-pvt-ltd/",
    },
  ];

  return (
    <div className="footer-container">
      <div className="footer-wrap">
        <div className="social-media">
          <div className="social-media-wrap">
            <div className="social-logo">Behelam</div>
            <div className="website-rights">
              Behelam © {new Date().getFullYear()} All rights reserved.
            </div>
            <div className="social-icons">
              {socialLinks.map((social) => (
                <a
                  key={social.label}
                  className="social-icon-link"
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={social.label}
                >
                  <social.icon size={24} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
